<template>
    <ul>
        <li v-for="(item, index) in list" :key="item" class="text">
            {{ item }}
            <span v-if="index === 1" class="link">{{ $t('faq.startupShow.ios.appsStore') }}</span>
        </li>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            list: this.$t('faq.startupShow.ios.list').split(';')
        }
    }
}
</script>

<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
